<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body card-height">
        <div class="mb-2">
          <h3>
            นำเข้า
          </h3>
        </div>
        <import-list :type="text" />
      </div>
      <!-- end card-body -->
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import ImportList from './import_list.vue'

export default {
  components: {
    ImportList
  },
  data () {
    return {
      text: 'สร้าง'
    }
  },
  watch: {
  },
  created () {

  },
  methods: {

  }
}
</script>

<style >
.modal-title {
  color: white;
}
</style>
